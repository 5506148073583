:root {
  --blue: #007bff;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #18b4e9;
  --secondary: #6c757d;
  --success: #28a745;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
}

.layout {
  background: #f0f0fa;
}

.header {
  border-bottom: 1px solid #0000001a;
}

.header .header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
}

.right-side-items > .item {
  margin: 0 15px;
}
.right-side-items .org_logo {
  background-color: #fff;
  height: 40px;
  border-radius: 5px;
  padding: 1px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  cursor: pointer;
  transition: 0.2s;
}
.right-side-items .org_logo:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.right-side-items {
  display: flex;
  align-items: center;
}

.left-items {
  display: flex;
  align-items: center;
}

.left-items .search_feild {
  margin: 0 10px;
  border-radius: 50px;
}

.sidebar-menu svg {
  font-size: 12px;
}
.sidebar-menu .ant-menu-submenu-title {
  margin: 5px 0;
}
