.card {
  /* width: 20cm; */
  position: relative;
  padding: 0px 20px 30px 17px;
  font-family: 'Source Sans Pro', sans-serif !important;
  height: 5.60in;
  max-height: 5.60in;

}

.card:before {
  display: block;
  background: #ebebeb;
  width: 3.5%;
  height: 65%;
  position: absolute;
  content: '';
  left: 0;
  clip-path: polygon(100% 0, 100% 86%, 0% 100%, 0 0%);
}

.card:after {
  display: block;
  content: '';
  width: 5%;
  height: 75%;
  background: #ebebeb;
  right: 0;
  position: absolute;
  bottom: 0;
  clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0 15%);
}

.RECEIPT {
  position: absolute;
  right: -58px;
  transform: rotate(-90deg);
  top: 59%;
  color: #fff;
  z-index: 1;
  font-size: 35px;
  -webkit-text-stroke-color: hsla(0, 0%, 100%, 0.8117647058823529);
  -webkit-text-fill-color: rgba(2, 1, 1, 0);
  -webkit-text-stroke-width: 0.5px;
  font-family: sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info1 {
  display: flex;
  justify-content: space-between;
}

.disFlex {
  display: flex;
}



.spanText {
  margin-bottom: 0.8rem;
  border-width: 0;
  border-bottom-width: 1px;
  border-style: dotted;
  margin-left: 2px;
}

.spanText2 {
  margin-bottom: 0.8rem;
  border-width: 0;
  border-bottom-width: 1px;
  border-style: dotted;
}

.section1 {
  display: flex;
  align-items: center;
}


.ms_1 {
  margin-inline-start: 0.25rem;
}

.f_mt2 {
  display: flex;
}

.foo_1 {
  display: flex;
  margin-top: 0.8rem;
  justify-content: center;
  gap: 50px;
  font-size: 18px;
  font-weight: bold;
}

.foo_2 {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
}

.foo_3 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 30px;
}

.foo_4 {
  border-width: 0;
  border-top-width: 1px;
  border-style: solid;
}

.footerAmount {
  padding: 5px;
  font-weight: bold;
  font-size: 15px;
  border: 1px dotted;
  border-radius: 5px;
}

.footerAmount_no_border {
  padding: 5px;
  font-weight: bold;
  font-size: 15px;

}